<template>
  <!-- Templates table -->
  <section class="w-full h-full">
    <PageHeader
      :heading="$t('xpbx.settings.timezones.heading')"
      :showButton="false"
    />

    <!-- Templates table -->
    <div class="card relative table-wrapper">
      <!-- Table -->
      <DataTable
        ref="dt"
        selectionMode="single"
        dataKey="id"
        :paginator="true"
        :rows="10"
        scrollable
        :scrollHeight="`${scrollHeight}px`"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        :value="timezones"
        filterDisplay="menu"
        paginatorPosition="top"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} timezones"
        :rowsPerPageOptions="[5, 10, 25]"
        v-if="!loading"
        :globalFilterFields="['description']"
      >
        <template #empty>
          {{ $t("xpbx.settings.fields.empty_table") }}</template
        >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div>
              <Button
                :label="$t('xpbx.button.new')"
                class="mr-2 add-record-button"
                @click="openNew"
              />
            </div>
            <div class="flex gap-2 items-center">
              <InputText
                class="search-input"
                v-model="filters['global'].value"
                :placeholder="$t('xpbx.settings.timezones.placeholders.search')"
              />
              <Export
                :dt="dt"
                :tableData="mapedTimezones"
                :columns="timezonesHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          sortable
          field="description"
          :header="$t('xpbx.settings.timezones.field.description')"
        >
          <template #body="{ data }">
            {{ data?.description }}
            <div>
              <TableActions
                :data="data"
                :id="data.id"
                :isActive="data.is_active"
                @edit="editHandle"
                @setActive="setActive"
                @delete="deleteSelectedRecord"
              />
            </div>
          </template>
        </Column>
        <Column
          sortable
          field="priority"
          :header="$t('xpbx.settings.timezones.field.priority')"
        >
          <template #body="{ data }">
            {{ data?.priority }}
          </template>
        </Column>
      </DataTable>
      <Loader v-else />
    </div>

    <!-- Dialogs -->
    <Dialog
      v-model:visible="deleteRecordsDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.description">{{
          $t("xpbx.settings.timezones.notification.confirm_delete", {
            delete: `timezone ${selectedRecord?.description}`,
          })
        }}</span>
      </div>
      <template #footer>
        <Button
          text
          :label="$t('xpbx.button.no')"
          @click="deleteRecordsDialog = false"
        />
        <Button
          text
          :label="$t('xpbx.button.yes')"
          @click="deleteSelectedRecords"
        />
      </template>
    </Dialog>

    <!-- Create timezone -->
    <Dialog
      v-model:visible="templateCreateDialog"
      :style="{ width: '700px' }"
      :header="$t('xpbx.settings.timezones.headings.create_timezone')"
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <div>
        <FormInput
          id="description"
          :error="errors?.description?.[0]"
          v-model:modelValue="timezone.description"
          :label="$t('xpbx.settings.timezones.labels.description')"
          :hint="$t('xpbx.settings.timezones.hints.description')"
        />

        <InputSwitch
          v-model:modelValue="timezone.is_active"
          :label="$t('xpbx.settings.queues.labels.is_active')"
        />

        <FormDropdown
          :options="priorityOptions"
          :error="errors?.priority?.[0]"
          v-model:modelValue="timezone.priority"
          :label="$t('xpbx.settings.timezones.labels.priority')"
          :hint="$t('xpbx.settings.timezones.hints.priority')"
        />
      </div>
      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="templateCreateDialog = false"
        />
      </template>
    </Dialog>
    <!-- Edit timezone -->
    <Dialog
      v-model:visible="templateDialog"
      :style="{ width: '700px' }"
      :header="$t('xpbx.settings.timezones.headings.edit_timezone')"
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <div class="flex items-center py-2 gap-2 mb-2">
        <p
          class="w-1/2 cursor-pointer pb-1"
          @click="activeTab = 1"
          :class="{ 'active-tab': activeTab === 1 }"
        >
          {{ $t("xpbx.settings.timezones.headings.edit_timezone") }}
        </p>
        <p
          class="w-1/2 cursor-pointer pb-1"
          @click="activeTab = 2"
          :class="{ 'active-tab': activeTab === 2 }"
        >
          {{ $t("xpbx.settings.timezones.headings.edit_opening_hours") }}
        </p>
      </div>
      <div v-if="activeTab === 1">
        <FormInput
          id="description"
          :error="errors?.description?.[0]"
          v-model:modelValue="timezone.description"
          :label="$t('xpbx.settings.timezones.labels.description')"
          :hint="$t('xpbx.settings.timezones.hints.description')"
        />

        <FormDropdown
          :options="priorityOptions"
          v-model:modelValue="timezone.priority"
          :error="errors?.priority?.[0]"
          :label="$t('xpbx.settings.timezones.labels.priority')"
          :hint="$t('xpbx.settings.timezones.hints.priority')"
        />

        <InputSwitch
          v-model:modelValue="timezone.is_active"
          :label="$t('xpbx.settings.timezones.labels.is_active')"
        />
      </div>
      <div v-if="activeTab === 2">
        <OpeningHours
          :openingHours="openingHours"
          v-if="openingHours?.monday"
          @changeRange="changeRange"
        />
      </div>

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="updateHandler"
          @cancel="templateDialog = false"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useTimezones from "@/modules/xpbx/composables/useTimezones";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import OpeningHours from "@/modules/xpbx/pages/settings/timezones/components/OpeningHours";
import AddOpeningHours from "@/modules/xpbx/pages/settings/timezones/components/AddOpeningHours";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Tooltip from "primevue/tooltip";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { validateTimezone } from "@/composables/auth/templateValidations";
import { timezonesHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "Timezones",

  components: {
    PageHeader,
    Loader,
    DataTable,
    Column,
    Button,
    InputText,
    Export,
    Dialog,
    OpeningHours,
    AddOpeningHours,
    QueueHint,
    FormInput,
    FormDropdown,
    InputSwitch,
    TableActions,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    // Datatable
    const dt = ref();
    const activeTab = ref(1);
    const submitted = ref(false);
    const { errors, clearErrors } = validator();
    const templateDialog = ref(false);
    const templateCreateDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const scrollHeight = ref(window.innerHeight - 290);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const mapedTimezones = computed(() => {
      return timezones.value.map((timezone) => {
        return {
          ...timezone,
          is_active: timezone.is_active ? "Active" : "Inactive",
        };
      });
    });

    const {
      findTimezones,
      findTimezone,
      createTimezone,
      updateTimezone,
      deleteTimezone,
      updateTimezoneData,
      openingHours,
      timezone,
      loading,
      timezones,
    } = useTimezones();

    const editHandle = async (data) => {
      selectedRecord.value = data;
      const form = {
        ...data,
        is_active: data.is_active === 1 ? true : false,
        priority: `${data?.priority || 0}`,
      };

      await findTimezone(data.id);
      timezone.value = form;
      templateDialog.value = true;
    };

    // Data Table Methods
    const openNew = () => {
      timezone.value = {
        description: "",
        is_active: true,
        priority: "0",
      };

      templateCreateDialog.value = true;
    };

    const reset = () => {
      timezone.value = {
        description: "",
        is_active: true,
        priority: "0",
      };
      activeTab.value = 1;
      submitted.value = false;
      selectedRecord.value = null;
      clearErrors();
    };

    const closeDialog = (value) => {
      templateDialog.value = value;
    };

    const updateOpeningHours = async () => {
      await updateTimezoneData(openingHours.value, selectedRecord.value?.id);
    };

    const update = async () => {
      const isValid = validateTimezone(timezone.value);
      console.log("isValid", isValid);

      if (!isValid) return;

      const form = {
        description: timezone.value.description,
        is_active: timezone.value.is_active === true ? 1 : 0,
        priority: timezone.value?.priority?.value ?? timezone.value.priority,
      };

      await updateTimezone(form, selectedRecord.value?.id);
      templateDialog.value = false;
    };

    const create = async () => {
      submitted.value = true;

      const isValid = validateTimezone(timezone.value);

      if (!isValid) return;

      const form = {
        description: timezone.value.description,
        is_active: timezone.value.is_active === true ? 1 : 0,
        priority: timezone.value?.priority?.value ?? timezone.value.priority,
      };

      // Send the data to the server
      await createTimezone(form);

      templateCreateDialog.value = false;
    };

    const updateHandler = async () => {
      submitted.value = true;

      if (activeTab.value === 1) {
        await update();
      } else {
        await updateOpeningHours();
        templateDialog.value = false;
      }
    };

    const deleteSelectedRecord = async (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteTimezone(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const changeRange = ({ day, ranges }) => {
      openingHours.value[day] = ranges;
    };

    const setActive = async (data) => {
      const form = {
        priority: data.priority,
        description: data.description,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateTimezone(form, data?.id);
    };

    const priorityOptions = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
    ];

    watch(templateDialog, (value) => {
      if (!value) reset();
    });

    watch(templateCreateDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findTimezones();
    });

    return {
      dt,
      filters,
      errors,
      loading,
      timezone,
      openingHours,
      activeTab,
      timezones,
      findTimezone,
      priorityOptions,
      templateDialog,
      templateCreateDialog,
      timezonesHeaders,
      mapedTimezones,
      submitted,
      scrollHeight,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      create,
      updateHandler,
      reset,
      openNew,
      editHandle,
      setActive,
      closeDialog,
      changeRange,
      deleteSelectedRecord,
      deleteSelectedRecords,
      updateTimezone,
      deleteTimezone,
      updateTimezoneData,
    };
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.active-tab {
  color: #32bcad;
  border-bottom: 2px solid #32bcad;
}
</style>